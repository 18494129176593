import React, { useEffect, useState } from "react"
import { BsExclamation } from "react-icons/bs"
import { FiXCircle } from "react-icons/fi"

import { styled } from "styled-components"

// Definindo um tipo apenas para as propriedades built-in do input HTML
interface CustomProps {
    // Adicione suas propriedades personalizadas aqui
    onChange?: React.ChangeEventHandler<HTMLInputElement>
    hint?: number
    status?: boolean
    index: number
    exercise: string
    onChangeText?: (v: string) => void
    inputProps: React.InputHTMLAttributes<HTMLInputElement>
}

// Defina o tipo do input do exercício combinando as propriedades built-in com as personalizadas
type ExerciseInputProps = CustomProps

const Hint = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    p,
    button {
        position: absolute;
        right: 0;
        height: 100%;
        background-color: #444;
        color: white;
        font-weight: bold;
        padding: 5px;
        min-width: 25px;
        font-size: 12px;

        justify-content: center;
        align-items: center;
    }

    input {
        /* Remove as setas padrão do input tipo number */
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
`

const ExerciseInput: React.FC<ExerciseInputProps> = (props) => {
    const [value, setValue] = useState(props.inputProps.defaultValue)

    useEffect(() => {
        setValue(props.inputProps.defaultValue)
    }, [props.inputProps.defaultValue])

    /*function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value)
        if (props.onChangeText) props.onChangeText(event.target.value)
    }*/

    return (
        <Hint>
            <input
                {...props.inputProps}
                onChange={props.onChange}
                onBlur={props.onChange}
                data-index={props.index}
                data-exercise={props.exercise}
                autoCorrect="off"
                autoComplete="off"
                type="number"
                inputMode="decimal"
                pattern="\d*"
            />
            {props.hint !== undefined ? (
                <button tabIndex={-1}>
                    {props.status && (
                        <div
                            style={{
                                position: "absolute",
                                top: -1,
                                right: -2.5,
                            }}
                        >
                            <BsExclamation size={12} color="yellow" />
                        </div>
                    )}

                    {props.hint}
                </button>
            ) : (
                <p style={{ opacity: 0.5 }}>
                    <FiXCircle />
                </p>
            )}
        </Hint>
    )
}

export default ExerciseInput
