/* eslint-disable @typescript-eslint/no-explicit-any */

import { AxiosInstance } from "axios"
import Cookies from "js-cookie"
import { ReqConfig, Tokens } from "./token-interfaces"

import { QueryClient } from "react-query"
import { isTokenExpired } from "./jwtUtils"

export const TAG_REFRESH = "@refresh:token"
export const TAG_ACCESS = "@access:token"

export default class TokenManager {
    private queryClient?: QueryClient
    private api: AxiosInstance
    private requested: string | undefined = undefined
    private pendingPromises: Array<(token?: string) => void> = []

    private ignoredUrls = [
        "login", //Plataform Log In
        "/login",
        "register", //Platform Sign In
        "/register",
        "auth", //Token Gen
        "/auth",
        "recover", //Recover
        "/recover",
        "reset", //Recover
        "/reset",
        "/students/check", //Fisrt Access
        "students/check",
        "/students/update", //Fisrt Access set pass
        "students/update",
    ]

    constructor(apiInstance: AxiosInstance, queryClient?: QueryClient) {
        this.api = apiInstance

        if (Cookies.get(TAG_ACCESS))
            this.api.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${Cookies.get(TAG_ACCESS)}`

        this.setupInterceptors()
        this.queryClient = queryClient
    }

    public cleanUp() {
        Cookies.remove(TAG_ACCESS)
        Cookies.remove(TAG_REFRESH)
        Cookies.remove("@trainerv2")
    }

    private setupInterceptors() {
        this.api.interceptors.request.use(
            this.Handle.bind(this),
            this.errorHandler
        )
        this.api.interceptors.response.use(
            (response) => response,
            this.HandleResError.bind(this)
        )
    }

    private async Handle(config: ReqConfig): Promise<ReqConfig> {
        if (this.ignoredUrls.some((url) => config.url?.startsWith(url)))
            return config

        const tokens = this.GetTokens()

        // Set Authorization if it's not present
        if (!config.headers.Authorization) {
            config.headers["Authorization"] = `Bearer ${tokens.access}`
            this.updateApi(tokens.access)
        }

        // Check if the access token is still valid
        if (tokens.access && !isTokenExpired(tokens.access)) {
            return config
        }

        // If a refresh is already pending, queue this request until the token is refreshed
        if (this.requested) {
            return new Promise((resolve) => {
                this.pendingPromises.push((newToken) => {
                    if (newToken) this.updateApi(newToken)
                    config.headers["Authorization"] = `Bearer ${newToken}`
                    resolve(config)
                })
            })
        }

        // Mark as "requested" to prevent multiple refresh calls
        this.requested = tokens.refresh

        try {
            // Otherwise, proceed to refresh the token
            this.queryClient?.invalidateQueries()
            const res = await this.api.post<Tokens>("/auth/refresh", {
                refresh: tokens.refresh,
            })

            this.SetTokens(res.data)

            // Resolve Pending Promises
            this.pendingPromises.forEach((callback) =>
                callback(res.data.access)
            )
            this.pendingPromises = []
            config.headers["Authorization"] = `Bearer ${res.data.access}`
        } catch (error) {
            const refresh = Cookies.get(TAG_REFRESH)

            if (refresh === undefined) {
                window.location.href = `/expired?error=RENEWING&ref=${"invalid"}`
                this.pendingPromises = []
            } else {
                this.pendingPromises.forEach((callback) => callback(undefined))
                this.pendingPromises = []
            }
        } finally {
            // Clear the "requested" flag after refresh attempt
            this.requested = undefined
        }

        return config
    }

    private errorHandler(error: any) {
        return Promise.reject(error)
    }

    private HandleResError(error: any) {
        return Promise.reject(error)
    }

    private updateApi(token?: string) {
        const auth = token ? `Bearer ${token}` : undefined
        this.api.defaults.headers.common["Authorization"] = auth
    }

    SetTokens(data: Tokens) {
        if (data.access && data.refresh) {
            this.api.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${data.access}`
            Cookies.set(TAG_ACCESS, data.access, { expires: 30 })
            Cookies.set(TAG_REFRESH, data.refresh, { expires: 30 })
        }
    }

    GetTokens(): Tokens {
        return {
            access: Cookies.get(TAG_ACCESS),
            refresh: Cookies.get(TAG_REFRESH),
        }
    }
}
