import styled from "styled-components"
import React, { useEffect, useRef, useState } from "react"

const FixedDivElement = styled.div<{ bottom: boolean; background: boolean }>`
    position: fixed;

    width: 100%;

    @media (min-width: 768px) {
        position: absolute;
    }

    flex-direction: column;
    ${(p) => (p.bottom ? "bottom: 0" : "top: 0")};
    left: 0;
    right: 0;

    z-index: 10;
    ${(p) =>
        p.background &&
        "background: linear-gradient(to bottom, #1d1d1d, #1d1d1d00);"}
`

const FixedDiv: React.FC<{
    children: React.ReactNode
    isBottom?: boolean
}> = ({ children, isBottom = true }) => {
    const [footerHeight, setFooterHeight] = useState<number>(0)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (ref.current) {
            setFooterHeight(ref.current.clientHeight)
        }
    }, [children])

    return (
        <div>
            <div style={{ height: footerHeight }}></div>
            <FixedDivElement bottom={isBottom} background ref={ref}>
                {children}
            </FixedDivElement>
        </div>
    )
}

export default FixedDiv
