import React, { useContext } from "react"
import { PlanButton, PlanItemHeader, Wrapper } from "./styles"
import { useQuery } from "react-query"
import api from "../../../../api"

import { PiBarbellDuotone } from "react-icons/pi"
import { useNavigate } from "react-router-dom"
import AppHeader from "../../../../components/app-header"
import { Column, Row } from "../../../../assets/global-styles"
import moment from "moment"
import { ProtocolType } from "../../../fithub-shared/interfaces/protocol"
import EmptyArray from "src/components/EmptyArray"
import LoadingIndicator from "src/components/LoadingIndicator"
import StartedShortcut from "src/components/StartedShortcut"
import AuthContext from "src/contexts/auth"

const ProtocolsList: React.FC = () => {
    const auth = useContext(AuthContext)

    const { data, isLoading } = useQuery(
        ["protocols", auth.trainer?.name],
        () =>
            api
                .post<ProtocolType[]>("/trainee/list/protocols", {
                    rel: auth.trainer?.id,
                })
                .then((res) => res.data)
                .catch((err) => {
                    alert(err)
                    return undefined
                })
    )

    const navigate = useNavigate()

    const DumbellIcon = () => {
        return (
            <div
                style={{
                    transform: "rotate(-45deg)",
                    marginRight: 5,
                }}
            >
                <PiBarbellDuotone size={18} color="var(--font)" />
            </div>
        )
    }

    return (
        <Wrapper className={"page"}>
            <AppHeader goBack title="Protocolos" />
            <div style={{ width: "100%", padding: 10 }}>
                <StartedShortcut />
            </div>

            {data === undefined || data.length === 0 ? (
                isLoading ? (
                    <LoadingIndicator isLoading={isLoading} />
                ) : (
                    <EmptyArray itemTitle="Planos de Treinos" />
                )
            ) : (
                <Column
                    style={{
                        flex: 1,
                        padding: 10,
                        justifyContent: "center",
                    }}
                >
                    <ul>
                        {data &&
                            data.map((e, i) => {
                                return (
                                    <PlanButton
                                        key={i}
                                        onClick={() => {
                                            navigate(`workouts?pid=${e.id}`)
                                        }}
                                    >
                                        <PlanItemHeader>
                                            <Row>
                                                <DumbellIcon />
                                                <h4>{e.title}</h4>
                                            </Row>

                                            <h4>
                                                {moment(e.created_at).format(
                                                    "DD/MM/yyyy"
                                                )}
                                            </h4>
                                        </PlanItemHeader>
                                        <div style={{ padding: 5 }}>
                                            <p>
                                                Possui {e.workouts_count} treino
                                                {e.workouts_count > 1 && "s"}.
                                            </p>
                                        </div>
                                    </PlanButton>
                                )
                            })}
                    </ul>
                </Column>
            )}
        </Wrapper>
    )
}

export default ProtocolsList
