import React from "react"

import { LinkParagraph, Panel, ScrollView } from "../../styles"
import { Crossfit } from "src/modules/fithub-shared/interfaces/workout"
import EmptyArray from "src/components/EmptyArray"
import { tornarLinksClicaveis } from "src/assets/global/functions"

// import { Container } from './styles';

const CrossfitView: React.FC<{ content: Crossfit }> = (props) => {
    const { instructions } = props.content

    const adicionarLinks = (texto: string) => {
        // Expressão regular para encontrar URLs no texto
        const regex = /(https?:\/\/[^\s]+)/g
        const partes = texto.split(regex)
        return partes.map((parte, index) => {
            if (parte.match(regex)) {
                return (
                    <a
                        key={index}
                        href={parte}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {parte}
                    </a>
                )
            } else {
                // Renderizar apenas <p> se a parte não for um link
                return parte.trim() !== "" ? <p>{parte}</p> : null
            }
        })
    }

    const makeLinksClickable = () => {
        // Expressão regular para encontrar URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g

        // Substitui cada URL encontrada por um link clicável
        const formattedContent = instructions.replace(urlRegex, (url) => {
            return `<a href="${url}" target="_blank">${url}</a>`
        })

        // Retorna o conteúdo formatado como HTML
        return { __html: formattedContent }
    }

    return (
        <ScrollView>
            {instructions !== "" ? (
                <>
                    <Panel style={{ padding: 15 }}>
                        <h4>Instruções para Treino</h4>
                        <span style={{ margin: 10 }} />
                        <LinkParagraph
                            dangerouslySetInnerHTML={makeLinksClickable()}
                        />

                        <span style={{ margin: 5 }} />
                    </Panel>
                </>
            ) : (
                <EmptyArray itemTitle="Exercícios" />
            )}
        </ScrollView>
    )
}

export default CrossfitView
