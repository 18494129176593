import moment from "moment"
import React from "react"
import { HiChevronRight } from "react-icons/hi"
import { PiFireDuotone } from "react-icons/pi"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import api from "src/api"

import { styled } from "styled-components"

import "moment/locale/pt-br"
import { removeItem } from "src/modules/navigation/screens/plan-view/workout-view/Weight/View/Content/hooks/storageUtils"
import { useAuthContext } from "src/contexts/auth"

// import { Container } from './styles';

const ActionButton = styled.button`
    width: 100%;
    justify-content: space-between;
    align-items: center;

    > div {
        align-items: center;
        flex-direction: row;
        > div {
            padding-left: 10px;
            flex-direction: column;
        }
    }
    padding: 10px;

    background-color: var(--background);
    border: 1px solid var(--border);
    border-radius: 5px;

    h5 {
        font-weight: normal;
    }
`

const StartedShortcut: React.FC = () => {
    const auth = useAuthContext()

    const { data } = useQuery("current-w", () =>
        api
            .post<{ plan: string; workout: string; date: string }>(
                "/chargepro/active",
                { trainer: auth.trainer?.id }
            )
            .then((res) => res.data)
            .catch(() => undefined)
    )

    const nav = useNavigate()

    function GoToWorkout() {
        removeItem("current-w")
        if (data)
            nav(`/plans/workouts/view?pid=${data.plan}&wid=${data.workout}`)
    }

    if (data) {
        return (
            <ActionButton onClick={GoToWorkout}>
                <div>
                    <PiFireDuotone size={24} />
                    <div>
                        <h4>Retomar treino em Andamento</h4>
                        <h5>
                            Você iniciou um treino em{" "}
                            {moment(data.date).format("LLL")}
                        </h5>
                    </div>
                </div>
                <HiChevronRight size={24} />
            </ActionButton>
        )
    }
    return <React.Fragment />
}

export default StartedShortcut
