import React, { ReactNode } from "react"
import { MdError } from "react-icons/md"
import { TbError404 } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { BeatLoader } from "react-spinners"
import { tokenManager } from "src/api"
import styled from "styled-components"

// import { Container } from './styles';

const Container = styled.div`
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex-direction: column;

    color: var(--font);

    button {
        border-radius: 50px;
        background-color: black;
        color: white;
        padding: 5px;
        width: 100px;
        margin-top: 30px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;

        justify-content: center;

        align-items: center;
    }

    p {
        font-size: 16px;
    }
`

const LoadingIndicator: React.FC<{
    children?: ReactNode
    isLoading: boolean
}> = ({ children, isLoading }) => {
    if (isLoading)
        return (
            <Container>
                <BeatLoader color="white" />
                {children}
            </Container>
        )
    else return <ErrorIndicator />
}

const ErrorIndicator: React.FC = () => {
    //const nav = useNavigate()

    function goback() {
        window.location.reload()
    }

    return (
        <Container>
            <TbError404 size={40} />
            <p>Houve algum erro!</p>
            <button onClick={goback}>RECARREGAR</button>
        </Container>
    )
}

export default LoadingIndicator
