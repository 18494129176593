import GlobalStyles, {
    MobileContainer,
    MobileBorders,
} from "./assets/global-styles"
import { BrowserRouter } from "react-router-dom"
import { QueryClientProvider } from "react-query"
import { AuthProvider } from "./contexts/auth"
import Rts from "./modules/routes"
import SvgTop from "./components/SvgTop"
import { useEffect } from "react"
import moment from "moment"
import { queryClient } from "./api"
import Cookies from "js-cookie"

const version = "1.0"
const TAG_UPDATE = `app-update@${version}`

function App() {
    async function UpdateCookie() {
        if (!Cookies.get(TAG_UPDATE)) {
            await Cookies.set(TAG_UPDATE, version, { expires: 2 })
            window.location.reload()
        }
    }

    useEffect(() => {
        moment.locale("pt")
        UpdateCookie()
    }, [])

    return (
        <MobileContainer>
            <GlobalStyles />

            <MobileBorders id="app-root" className="dark">
                <SvgTop />

                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <AuthProvider>
                            <Rts />
                        </AuthProvider>
                    </BrowserRouter>
                </QueryClientProvider>
            </MobileBorders>
        </MobileContainer>
    )
}

export default App
