import React, { useContext, useEffect, useState } from "react"
import { Form, Wrapper } from "./styles"
import api from "src/api"
import { useLocation, useNavigate } from "react-router-dom"
import MaskInput from "src/components/inputfield/masked-input"
import { Logo } from "../trainee-auth/styles"
import Inputfield from "src/components/inputfield"
import {
    MdCheckCircle,
    MdEmail,
    MdLock,
    MdPermIdentity,
    MdPhone,
} from "react-icons/md"
import {
    birthdateMask,
    identityMask,
    phoneMask,
} from "../../../../modules/fithub-shared/functions/functions"

import { TbCandle } from "react-icons/tb"
import moment from "moment"
import AuthContext from "src/contexts/auth"
import { ValidateEmail, ValidatePass } from "./validations"
// import { Container } from './styles';

interface UserResponse {
    name: string
    email: string
    identity: string
    phone: string
    birthdate: string
    password: string
    confirmPass: string
}

interface Validation {
    valido: boolean
    mensagem: string[]
}

const FirstAccess: React.FC = () => {
    const navigate = useNavigate()
    const context = useContext(AuthContext)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")

    const [user, setUser] = useState<UserResponse>()

    const [validPass, setValidPass] = useState<Validation>()
    const [validEmail, setValidEmail] = useState(ValidateEmail(user?.email))

    useEffect(() => {
        password()

        if (!id) navigate("/")
    }, [])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value, name } = e.target

        if (name === "password") {
            setValidPass(ValidatePass(value))
        }

        if (name === "email") {
            setValidEmail(ValidateEmail(value))
        }

        const v = {
            ...user,
            [name]: value,
        } as UserResponse

        setUser(v)
    }

    async function Apply() {
        if (user?.password !== user?.confirmPass) {
            alert("As senhas não conferem!")
        } else {
            const res = await api
                .post("/students/update/", { id, ...user })
                .then((res) => res)
                .catch((err) => alert(err))

            if (res && user && res.status === 200) {
                await context.Login(user.email, user.password).then((res) => {
                    navigate(`/`)
                })
            }
        }
    }

    const password = async () => {
        await api
            .post("/students/check/", { id })
            .then((res) => {
                if (res.status === 201) {
                    const nuser = res.data as UserResponse
                    nuser.password = ""

                    nuser.birthdate = moment(new Date(nuser.birthdate)).format(
                        "DDMMYYYY"
                    )

                    setUser(nuser)
                } else {
                    navigate("/", { state: { email: res.data as string } })
                }
            })
            .catch((err) => {
                navigate("/")
                //alert(err)
            })
    }

    if (!user) return <>Carregando...</>
    else
        return (
            <Wrapper>
                <Logo />
                <span />
                <p>Bem vindo, {user.name.split(" ")[0]}!</p>
                <span />
                <p>Para iniciar, confira seus dados e conclua seu cadastro.</p>
                <span />
                <span />
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Inputfield
                        name="email"
                        error={validEmail}
                        onChange={handleChange}
                        autoComplete="new-email"
                        autoCapitalize="none"
                        defaultValue={user.email}
                        icon={MdEmail}
                        placeholder="e-mail"
                    />

                    <span />
                    <MaskInput
                        name="phone"
                        onChange={handleChange}
                        autoComplete="new-phone"
                        defaultValue={user.phone}
                        value={user.phone}
                        icon={MdPhone}
                        placeholder="Celular"
                        mask={phoneMask}
                    />
                    <span />
                    <MaskInput
                        name="identity"
                        onChange={handleChange}
                        autoComplete="new-phone"
                        defaultValue={user.identity}
                        value={user.identity}
                        icon={MdPermIdentity}
                        placeholder="CPF / Identificação"
                        mask={identityMask}
                    />
                    <span />
                    <MaskInput
                        name="birthdate"
                        onChange={handleChange}
                        autoComplete="new-birthdate"
                        defaultValue={user.birthdate}
                        value={user.birthdate}
                        icon={TbCandle}
                        placeholder="Nascimento"
                        mask={birthdateMask}
                    />
                    <span />
                    <p>Crie uma senha:</p>
                    <span />
                    <Inputfield
                        name="password"
                        error={validPass && !validPass.valido}
                        onChange={handleChange}
                        defaultValue={user.password}
                        autoComplete="new-password"
                        autoCapitalize="none"
                        type={"password"}
                        icon={MdLock}
                        placeholder="Senha"
                    />
                    <span />
                    <Inputfield
                        name="confirmPass"
                        error={validPass && !validPass.valido}
                        onChange={handleChange}
                        defaultValue={user.confirmPass}
                        autoComplete="new-password-repeat"
                        autoCapitalize="none"
                        type={"password"}
                        icon={MdLock}
                        placeholder="Confirmar Senha"
                    />
                    <span />

                    {validPass && !validPass.valido && (
                        <p
                            style={{
                                color: "red",
                                fontSize: 14,
                                whiteSpace: "pre-wrap",
                            }}
                        >
                            {validPass?.mensagem.join("\n\n")}
                        </p>
                    )}

                    <span />

                    <button
                        disabled={!ValidatePass(user.password)}
                        onClick={Apply}
                    >
                        <MdCheckCircle style={{ marginRight: 5 }} />
                        <p>Concluir Cadastro!</p>
                    </button>
                </Form>
            </Wrapper>
        )
}

export default FirstAccess
