import axios from "axios"
import config from "./config"
import TokenManager from "src/modules/fithub-shared/TokenManager"
import { QueryClient } from "react-query"

const api = axios.create(config)

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchIntervalInBackground: false,
        },
    },
})

export const tokenManager = new TokenManager(api, queryClient)

export default api
