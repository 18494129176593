import { ExerciseExecCache } from "src/modules/fithub-shared/trainee/student-cache-classes"
import { useEffect, useState } from "react"

export function useCachedContentN(wid: string): {
    onChangeReps: (e: React.ChangeEvent<HTMLInputElement>) => void
    onChangeWeight: (e: React.ChangeEvent<HTMLInputElement>) => void
    setFeedback: (exercise: string, value: string) => void
    cache: ExerciseExecCache
} {
    const [cache, setCache] = useState<ExerciseExecCache>(
        new ExerciseExecCache()
    )

    const LoadCached = () => {
        const data = localStorage.getItem(`${wid}@values`)
        if (data) {
            // Re-hidratar a classe com os dados do localStorage
            const parsedData = JSON.parse(data)
            const hydratedCache = new ExerciseExecCache(parsedData)
            setCache(hydratedCache)
        }
    }

    useEffect(() => {
        LoadCached()
    }, [wid])

    const onChangeReps = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(e.target.getAttribute("data-index") ?? "")
        const exercise = e.target.getAttribute("data-exercise") ?? ""
        if (!exercise || isNaN(index)) return

        const value = parseInt(e.target.value) || undefined
        cache.setReps(exercise, index, value)
        saveCache()
    }

    const onChangeWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(e.target.getAttribute("data-index") ?? "")
        const exercise = e.target.getAttribute("data-exercise") ?? ""
        if (!exercise || isNaN(index)) return

        const value = parseFloat(e.target.value) || undefined
        cache.setWeight(exercise, index, value)
        saveCache()
    }

    const setFeedback = (exercise: string, value: string) => {
        cache.setFeedback(exercise, value)
        saveCache()
    }

    const saveCache = () => {
        localStorage.setItem(`${wid}@values`, JSON.stringify(cache.toJSON()))
        setCache(new ExerciseExecCache(cache.toJSON())) // Para garantir atualização do estado
    }

    return {
        onChangeReps,
        onChangeWeight,
        setFeedback,
        cache,
    }
}
