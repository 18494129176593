import FixedFooter from "src/components/FixedFooter"
import styled, { keyframes } from "styled-components"

// Defina a animação
const fadeInExpand = keyframes`
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`

export const WeightContainer = styled.div<{ interactable: boolean }>`
    position: relative;
    flex: 1;
    flex-direction: column;

    overflow: auto;

    align-items: center;

    padding: 10px;

    z-index: 0;

    pointer-events: ${(p) => (p.interactable ? "all" : "none")};

    * {
        pointer-events: ${(p) => (p.interactable ? "all" : "none")};
    }
`

export const ExerciseBackdrop = styled.div`
    position: fixed;
    display: flex;

    * {
        display: flex;
    }

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: 10;

    background-color: #000d;

    justify-content: center;
    align-items: center;
`

export const CommentsView = styled.div`
    color: white;
    font-size: 12px;
    white-space: pre-wrap;
    width: 100%;
    background-color: #2d2d2d;
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 5px;
    border: 1px solid var(--border);

    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;

    div {
        align-items: center;
        justify-content: center;
        p {
            font-weight: bold;
            margin-left: 10px;
        }
        margin-bottom: 10px;
    }
`

export const ExpandableExc = styled.button`
    background-color: #0008;
    padding: 10px;

    width: 100%;

    align-items: center;

    span {
        margin: 5px;
    }

    p {
        font-size: 12px;
    }

    margin-bottom: 5px;
    border-radius: 5px;
`

export const ExerciseContent = styled.div`
    width: 100%;
    flex: 1;
    flex-direction: column;

    button:active {
        transform: scale(1.05);
    }

    button:disabled {
        opacity: 0.2;
    }

    /* Aplica a animação quando a classe 'visible' é adicionada */
    .animated {
        animation: ${fadeInExpand} 200ms ease-in;
    }

    > div {
        width: 100%;
    }
`

export const NotStartedDiv = styled.div`
    justify-content: center;
    align-items: center;

    padding: 10;

    height: 50px;

    > p {
        font-size: 14px;
        padding-left: 10px;
        background-color: #444;
        padding: 5px;
        border-radius: 5px;
        margin-left: 10px;
    }
`

export const TrainingSheet = styled.table`
    flex-direction: column;
    font-size: 12px;
    user-select: none;
    padding-top: 10px;

    width: 100%;

    * {
        flex-shrink: 0;
    }
    thead {
        color: #fff;
        width: 100%;
        flex-direction: column;
        tr {
            width: 100%;
            padding-bottom: 10px;
            td {
                background-color: #2d2d2d;
                flex: 1;
                height: 28px;
                justify-content: center;
                align-items: center;

                input {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    outline: none;
                    border: none;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: white;
                    font-size: 16px;
                    font-weight: 600;
                }

                input::placeholder {
                    font-size: 12px;
                }
            }

            td:first-child {
                border-radius: 50%;
                margin-right: 8px;
                max-width: 28px;
                font-weight: 600;
            }

            td:nth-child(2) {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                margin-right: 8px;
            }

            td:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-left: 10px;
                max-width: 50px;
            }
        }
    }
`

export const YoutubeFrame = styled.div<{ scale: number; height: number }>`
    width: 100%;

    max-width: 768px;

    aspect-ratio: calc(16 / 9);

    iframe {
        height: 100%;
        width: 100%;

        border-radius: inherit;
        border: unset;
        zoom: 0.75;
    }

    border: 1px solid #000;

    border-radius: 5px;

    background-color: black;
`

export const TitleHeader = styled.div`
    width: 100%;
    flex-direction: row;
    align-items: center;

    padding-top: 10px;

    justify-content: space-between;

    > div {
        align-items: center;
    }
`

export const ExerciseTitle = styled.h3`
    font-size: 14px;
    width: 100%;

    color: white;
    margin-right: 5px;
    text-align: center;
`

export const TitleButtons = styled.button`
    background-color: #fff;
    flex-direction: column;

    height: 30px;
    width: 30px;
    border-radius: 45px;

    margin-left: 10px;

    :active {
        opacity: 0.75;
    }

    justify-content: center;
    align-items: center;
`

export const PlayStopButton = styled.button`
    background-color: var(--primary);
    border-radius: 8px;

    font-size: 16px;

    height: 50px;
    width: 200px;

    justify-content: center;
    align-items: center;

    flex-direction: column;
`

export const Controls = styled.div`
    flex: 1;
    width: 100%;
    padding: 10px;

    justify-content: center;

    align-items: end;
`

export const ChangeButton = styled.div`
    background-color: white;
    color: black;
    p {
        color: black;
    }

    :last-child {
        p {
            margin-right: 5px;
        }
    }

    font-weight: bold;
    padding: 5px;
    border-radius: 5px;

    align-items: center;
`

export const PlayOverlay = styled.div`
    flex-direction: column;
    position: absolute;
    z-index: 1;
    user-select: none;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: #f004;

    pointer-events: none;

    * {
        pointer-events: all;
    }
`

export const Footer = styled.div`
    width: 100%;
    justify-content: space-between;
    padding-inline: 5px;

    button {
        border-right: 1px solid #fff8;
    }

    button:last-child {
        border-right: none;
    }
`

export const FooterBtn = styled.button`
    flex-direction: column;
    flex: 1;

    height: 50px;

    p {
        margin-top: 5px;
        font-size: 10px;
    }

    justify-content: center;
    align-items: center;
`

export const IconButton = styled.button<{ disabled?: boolean }>`
    opacity: unset;
    display: flex;
    align-items: center;

    padding: 10px;

    background-color: var(--primary);

    font-size: 16px;

    border-radius: 25px;

    p {
        text-transform: uppercase;
        padding-bottom: 2.5px;
        padding-inline: 5px;
        font-weight: bold;
        font-size: 12px;
    }
`
