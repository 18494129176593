import React from "react"

import {
    AssessmentClass,
    AssessmentObject,
} from "src/modules/fithub-shared/anthropometry/assessment"
import {
    BonesListKeys,
    bonesKeys,
    getBonesKey,
} from "src/modules/fithub-shared/anthropometry/bones"
import {
    SkinFoldsList,
    SkinFoldsListKeys,
    foldsKeys,
    getFoldsKey,
} from "src/modules/fithub-shared/anthropometry/folds"
import {
    PerimetryListKeys,
    getPerimKey,
    perimKeys,
} from "src/modules/fithub-shared/anthropometry/perimetry"
import DonutChart from "src/components/DonutChart"
import { Header, Item, ItemSection, Key, Value } from "./styles"

// import { Container } from './styles';

const ContentView: React.FC<{ assets: AssessmentClass }> = ({ assets }) => {
    function ListingSection<T>(
        keys: {
            name: T
            pt: string
        }[],
        content: any,
        unit?: string
    ) {
        return (
            <ItemSection>
                {keys.map((key, index) => {
                    return (
                        <Item key={`${key}_${index}`}>
                            <Key>{key.pt}</Key>
                            <Value>
                                {JSON.stringify(content[key.name as any] ?? 0)}
                                {unit}
                            </Value>
                        </Item>
                    )
                })}
            </ItemSection>
        )
    }

    return (
        <div
            style={{
                all: "unset",
                display: "flex",
                paddingTop: 10,
                flexDirection: "column",
            }}
        >
            <DonutChart data={assets.graph} />

            <Header>Perimetria</Header>
            {ListingSection<PerimetryListKeys>(
                perimKeys,
                assets.content.perimetry,
                " mm"
            )}
            <Header>Dobras Cutâneas</Header>
            {ListingSection<SkinFoldsListKeys>(
                foldsKeys,
                assets.content.folds,
                " mm"
            )}
            <Header>Diametro Ósseo</Header>
            {ListingSection<BonesListKeys>(
                bonesKeys,
                assets.content.bones,
                " mm"
            )}
        </div>
    )
}

export default ContentView
