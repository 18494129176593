import { ChargeData } from "./interfaces"

// Classe individual para cada exercício
export class ExcDataCache {
    data: ChargeData[] = []
    feedback: string = ""
    wexec: string

    constructor(wexec: string) {
        this.wexec = wexec
    }

    setReps(index: number, reps: number | undefined) {
        if (!this.data[index]) {
            this.data[index] = {} // Ajuste de acordo com a interface ChargeData
        }
        this.data[index].reps = reps
    }

    setWeight(index: number, weight: number | undefined) {
        if (!this.data[index]) {
            this.data[index] = {}
        }
        this.data[index].weight = weight
    }

    setFeedback(feedback: string) {
        this.feedback = feedback
    }
}

// Classe principal que manipula o cache de execuções
export class ExerciseExecCache {
    cache: { [key: string]: ExcDataCache } = {}

    constructor(initialCache?: { [key: string]: ExcDataCache }) {
        if (initialCache) {
            Object.keys(initialCache).forEach((exercise) => {
                const exerciseData = initialCache[exercise]
                // Re-hidratar para garantir que estamos usando instâncias da classe ExcDataCache
                this.cache[exercise] = new ExcDataCache(exerciseData.wexec)
                this.cache[exercise].data = exerciseData.data
                this.cache[exercise].feedback = exerciseData.feedback
            })
        }
    }

    getExerciseData(exercise: string): ExcDataCache | undefined {
        return this.cache[exercise]
    }

    addExercise(exercise: string, wexec: string) {
        if (!this.cache[exercise]) {
            this.cache[exercise] = new ExcDataCache(wexec)
        }
    }

    setReps(exercise: string, index: number, reps: number | undefined) {
        this.addExercise(exercise, this.cache[exercise]?.wexec ?? "")
        this.cache[exercise].setReps(index, reps)
    }

    setWeight(exercise: string, index: number, weight: number | undefined) {
        this.addExercise(exercise, this.cache[exercise]?.wexec ?? "")
        this.cache[exercise].setWeight(index, weight)
    }

    setFeedback(exercise: string, feedback: string) {
        this.addExercise(exercise, this.cache[exercise]?.wexec ?? "")
        this.cache[exercise].setFeedback(feedback)
    }

    // Novos métodos para obter reps e weight
    getReps(exercise: string, index: number): number | undefined {
        return this.cache[exercise]?.data?.[index]?.reps
    }

    getWeight(exercise: string, index: number): number | undefined {
        return this.cache[exercise]?.data?.[index]?.weight
    }

    getFeedback(exercise: string): string {
        return this.cache[exercise]?.feedback ?? ""
    }

    toJSON() {
        return this.cache
    }
}
