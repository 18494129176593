import Cookies from "js-cookie"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import api, { tokenManager } from "src/api"
import { Storage } from "src/modules/fithub-shared/TokenManager/data-storage"
import { HypeUser } from "src/modules/fithub-shared/TokenManager/token-interfaces"

export interface Trainer {
    id: string
    name: string
    name_pj: string
    photoURL?: string
}

interface AuthContextData {
    signed: boolean
    user: HypeUser | undefined
    trainer: Trainer | undefined
    setTrainer(trainer: Trainer | undefined): void
    Login(user: string, password: string): Promise<string>
    Logout(): void
}

const AuthContext = createContext({} as AuthContextData)

export const useAuthContext = () => useContext(AuthContext)

interface Props {
    children: React.ReactNode
}

const TAG = "@TRUSSR:CCH"

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState(Storage.get<HypeUser>(TAG))

    const [trainer, setTrainer] = useState<Trainer | undefined>(
        Storage.get("@trainerv2")
    )

    const navigate = useNavigate()

    const queryClient = useQueryClient()

    useEffect(() => {
        try {
            if (user !== null) return
            setUser(Storage.renew(TAG))
        } catch {
            console.error("Não foi possível carregar sessão [1]")
        }
    }, [])

    function handleSetTrainer(trainer: Trainer | undefined) {
        setTrainer(trainer)
        if (trainer != null) Storage.set("@trainerv2", trainer)
    }

    function Logout() {
        navigate("/")
        setUser(undefined)
        Storage.remove(TAG)
        localStorage.clear()
        queryClient.clear()
        tokenManager.cleanUp()
    }

    async function Login(email: string, password: string) {
        const res = await api
            .post<HypeUser>(`/login`, {
                email: email.toLowerCase(),
                password,
            })
            .then((res) => {
                setUser(res.data)
                Storage.set(TAG, res.data)
                tokenManager.SetTokens(res.data.tokens)
                navigate("/")
            })
            .catch((err) => {
                return err.response.data.message
            })

        return res
    }

    return (
        <AuthContext.Provider
            value={{
                signed: user !== undefined,
                user,
                trainer,
                setTrainer: handleSetTrainer,
                Login: Login,
                Logout: Logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
