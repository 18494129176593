import { GetParameter } from "src/assets/functions"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { styled } from "styled-components"
import { useAuthContext } from "src/contexts/auth"
import { tokenManager } from "src/api"
import Cookies from "js-cookie"

// import { Container } from './styles';

const Button = styled.button`
    background-color: #444;
    padding: 8px;
    width: 150px;
    margin-top: 15px;

    justify-content: center;
    align-items: center;

    border-radius: 5px;
`

const Expired: React.FC<{ redirect?: string }> = ({ redirect }) => {
    const navigation = useNavigate()
    const auth = useAuthContext()

    const error = GetParameter("error")

    useEffect(() => {
        if (redirect) navigation(redirect)
    }, [])

    function Logout() {
        auth.Logout()
        navigation("/")
    }

    return (
        <div
            style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <p style={{ fontSize: 24, color: "#fff" }}>SESSÃO EXPIRADA!</p>
            <p style={{ fontSize: 14, color: "#fff" }}>{error}</p>
            <Button onClick={Logout}>
                <p style={{ fontSize: 12 }}>Voltar ao Login</p>
            </Button>
        </div>
    )
}

export default Expired
