import React, { useEffect, useState } from "react"
import { YoutubeFrame } from "../../styles"
import { GetVideo, YtToIframe } from "../../utils"
import convertYouTubeLinkToEmbed from "src/modules/fithub-shared/functions/youtube-utils"

// import { Container } from './styles';

const YoutubeView: React.FC<{ linkId: string }> = ({ linkId }) => {
    const [video, setVideo] = useState<string | undefined>()
    const [aspect, setAspect] = useState(1)
    useEffect(() => {
        setVideo(undefined)
        ReloadVideo(linkId)
    }, [linkId])

    function ReloadVideo(linkId?: string) {
        if (linkId)
            GetVideo(linkId).then((res) => {
                setVideo(res)
                if (res) setAspect(res?.includes("shorts") ? 1.5 : 1)
            })
        else setVideo(undefined)
    }

    return (
        <div
            style={{
                width: "100%",
                justifyContent: "center",
            }}
        >
            {video ? (
                <YoutubeFrame scale={1} height={200 * aspect}>
                    <iframe
                        width={aspect === 1 ? undefined : "50%"}
                        allow="fullscreen;"
                        title={linkId}
                        src={convertYouTubeLinkToEmbed(video)}
                        allowFullScreen={true}
                    />
                </YoutubeFrame>
            ) : (
                <YoutubeFrame scale={1} height={200 * aspect} />
            )}
        </div>
    )
}

export default YoutubeView
